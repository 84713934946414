import React, { FC } from "react";
import styles from "./styles.module.css";
import { ILoaderConfig } from "../../types/loader";

interface IProps {
  config: ILoaderConfig;
}

const Loader: FC<IProps> = ({ config }) => {
  const color = config.color || "#2D243E";

  console.log({ config });

  let appMgr: Window | null = null;
  try {
    appMgr = window.getAppWindow();
  } catch (e: any) {
    console.error("App Viewer not accessible", e.message);
  }

  const customLoader = appMgr?.application?.loaderIcon;

  if (customLoader) {
    return (
      <div className={styles.overlay}>
        <div className={styles.custom_loader_wrap}>
          <img alt="Loading..." src={customLoader} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.overlay}>
        <div className={styles.loader_wrap}>
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill={color} />
            <rect y="24" width="20" height="20" fill="#e5e5e5" />
            <rect x="24" width="20" height="20" fill="#e5e5e5" />
            <path d="M24 24H44V44H24V24Z" fill="#e5e5e5" />
          </svg>
          <div className={styles.loader_text}>Loading...</div>
        </div>
      </div>
    );
  }
};

export default Loader;
