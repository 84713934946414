import React, { FC } from "react";
import { IActionAlertObject } from "../../types/alert";
import styles from "./ActionAlert.module.css";
import { toast } from "react-toastify";
import { ACTIONABLE } from "../../constants";
import InfoIcon from "../icons/InfoIcon";

interface IProps {
  input: IActionAlertObject;
}

const ActionAlert: FC<IProps> = ({ input }) => {
  const onButtonClick = () => {
    toast.dismiss({ containerId: ACTIONABLE, id: input.id });
    (window.top || window).postMessage(
      {
        messageType: "SHOW_NOTIFICATION_ACTION",
        payload: input,
      },
      "*"
    );
  };
  const message = input.message || "";

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <InfoIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.message}>
          {message.length > 150 ? `${message.substring(0, 150)}...` : message}
        </div>
        <button onClick={onButtonClick} type="button" className={styles.button}>
          {input.name}
        </button>
      </div>
    </div>
  );
};

export default ActionAlert;
